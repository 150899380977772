import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BlackEmptySpace, BasicAppBar } from "component/components";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useHistory } from "react-router";
import logo_sm from "asset/logo_sm.png";
import logo_sm_white from "asset/logo_sm_white.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import GlobalContext from "context/globalContext";
import logo_black from "asset/2023-HIDEEP-logo-black.png";
import logo_white from "asset/2023-HIDEEP-logo-gray.png";
//careers

import touch1 from "asset/technology/stylus/touch1.jpeg";
import touch2 from "asset/technology/stylus/touch2.png";
import touch3 from "asset/technology/stylus/touch1.jpeg";
import touch4 from "asset/technology/stylus/touch2.png";
import touch5 from "asset/technology/stylus/touch3.jpeg";
import newStylus from "asset/technology/stylus/newStylus.jpeg";

import { Link } from "@mui/material";
import lastMobile from "asset/technology/stylus/lastmobile.jpeg";

import tech1 from "asset/technology/tech1.jpeg";
import tech2 from "asset/technology/tech2.jpeg";
import tech3 from "asset/technology/tech3.png";
import tech4 from "asset/technology/tech4.jpeg";
import tech5 from "asset/technology/tech5.png";
import tech6 from "asset/technology/tech6.png";
import tech7 from "asset/technology/tech7.jpeg";
import tech8 from "asset/technology/tech8.jpeg";
import tech9 from "asset/technology/tech9.jpeg";
import tech10 from "asset/technology/tech10.jpeg";
import tech11 from "asset/technology/tech11.jpeg";
import tech12 from "asset/technology/tech12.jpeg";
import tech13 from "asset/technology/tech13.jpeg";

import pdf from "asset/company/pdf.png";
import GoogleMapReact from "google-map-react";
import { GoogleMap, Marker as Marker1 } from "react-google-maps";
import { borders } from "@mui/system";

import styles from "styles/style";
import i18n from "i18n";
// import "./main.css";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";

import "./iamport.cm";
import { maxWidth } from "@mui/system";
import { BorderTop } from "@mui/icons-material";

function Company(props) {
  const history = useHistory();
  const VideoComponentLg = React.memo(() => {
    return (
      <iframe
        width="100%"
        height="470"
        // src="https://www.youtube.com/embed/GFFBaRxaM9s?autoplay=1&modestbranding=1&rel=0&fs=0"
        src="https://www.youtube-nocookie.com/embed/Bt5HMzOfNCc?autoplay=0&modestbranding=1&rel=0&fs=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    );
  }, []);
  const VideoComponentSm = React.memo(() => {
    return (
      <div style={{ width: "100%", aspectRatio: "1.776", lineHeight: "0" }}>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube-nocookie.com/embed/Bt5HMzOfNCc?autoplay=0&modestbranding=1&rel=0&fs=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    );
  }, []);
  const { t } = useTranslation();

  const [lang, setLang] = React.useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "ko"
  );

  React.useEffect(() => {
    console.log("렌더를 어떻게 유발 시키지", lang);
    if (lang === "ko") {
      i18n.changeLanguage("ko");
    } else {
      i18n.changeLanguage("en");
    }
  }, [lang]);
  const matches = useMediaQuery("(min-width:900px)");
  const [hover, setHover] = React.useState(false);
  const fixedBoxRef = React.useRef();
  const textBoxRef = React.useRef();
  const lastTextRef = React.useRef();

  const boxRef = React.useRef();

  const [refState, setRefState] = React.useState(null);
  const [textRefState, setTextRefState] = React.useState(null);
  const [lastTextRefState, setLastTextRefState] = React.useState(null);

  const [boxRefState, setBoxRefState] = React.useState(null);

  const [scrollPosition, setScrollPosition] = React.useState(0);

  const containerBottom =
    refState && refState.current.offsetTop + refState.current.offsetHeight;
  const containerTop = refState && refState.current.offsetTop;

  const containerHeight = refState && refState.current.offsetHeight;
  const fixedTop = refState && refState.current.offsetTop;
  const lastTextHeight =
    lastTextRefState && lastTextRefState.current.offsetHeight;
  const lastTextBottom =
    lastTextRefState &&
    lastTextRefState.current.offsetTop +
      lastTextRefState.current.offsetHeight +
      containerTop +
      lastTextHeight;

  const boxSize = boxRefState && boxRef.current.offsetHeight;
  const bottom = containerBottom - boxSize;
  const fixedBottom = refState && bottom;
  const fixedRange = bottom > scrollPosition && scrollPosition > fixedTop;

  const textHeight = textRefState && textRefState.current.offsetHeight;

  // React.useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  // React.useEffect(() => {
  //   if (fixedBoxRef.current) {
  //     setRefState(fixedBoxRef);
  //   }
  //   if (textBoxRef.current) {
  //     setTextRefState(textBoxRef);
  //   }
  //   if (lastTextRef.current) {
  //     setLastTextRefState(lastTextRef);
  //   }
  //   if (boxRef.current) {
  //     setBoxRefState(boxRef);
  //   }
  // }, [fixedBoxRef]);

  const [white, setWhite] = React.useState(true);
  const { drawerIsOpen, setDrawerIsOpen } = React.useContext(GlobalContext);
  const openDrawer = () => {
    setDrawerIsOpen(!drawerIsOpen);
  };
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position === 0) {
      setWhite(true);
    } else {
      setWhite(false);
    }
  };

  function ImgKeepHeight({ src }) {
    return (
      <div
        style={
          matches
            ? styles.imgKeepLengthBox.lgtheme
            : styles.imgKeepLengthBox.smtheme
        }
      >
        <img
          style={
            matches
              ? styles.imgKeepLength.lgtheme
              : styles.imgKeepLength.smtheme
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgWidthFit({ src, height, minHeight, width }) {
    return (
      <div
        style={
          matches
            ? {
                ...styles.imgWidthFitBox.lgtheme,
                height: height ?? "",
                width: width ?? "",
                backgroundColor: "#000",
                lineHeight: "0",
              }
            : {
                ...styles.imgWidthFitBox.smtheme,
                height: height ?? "",
                width: width ?? "",
                backgroundColor: "#000",
                lineHeight: "0",
                minHeight,
              }
        }
      >
        <img
          style={
            matches
              ? { ...styles.imgWidthFit.lgtheme, height: height ?? "" }
              : {
                  ...styles.imgWidthFit.smtheme,
                  height: height ?? "",
                  minHeight,
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgScaleFit({ src, height }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgScaleFitBox.lgtheme, height, overflow: "hidden" }
            : {
                ...styles.imgScaleFitBox.smtheme,
                maxHeight: height,
                overflow: "hidden",
              }
        }
      >
        <img
          style={
            matches
              ? {
                  ...styles.imgScaleFit.lgtheme,
                  minHeight: height,
                  overflow: "hidden",
                }
              : {
                  ...styles.imgScaleFit.smtheme,
                  maxHeight: height,
                  overflow: "hidden",
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function CustomContainer(props) {
    return (
      <div style={{ backgroundColor: "black" }}>
        <Container
          sx={{
            maxWidth: "1294px",
            padding: matches ? "0 5px" : "0 0px",
          }}
        >
          {props.children}
        </Container>
      </div>
    );
  }
  function PaddingBox({ children }) {
    return (
      <Box sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}>
        {children}
      </Box>
    );
  }
  function CustomColorContainer(props) {
    return (
      <div style={{ backgroundColor: "#1A1E24" }}>
        <Container
          sx={{
            maxWidth: "1294px",
            padding: matches ? "0 5px" : "0 0px",
            backgroundColor: "#1A1E24",
          }}
        >
          {props.children}
        </Container>
      </div>
    );
  }
  function PaddingColorBox({ children }) {
    return (
      <Box
        sx={
          matches
            ? { ...styles.paddingBox.lgtheme, backgroundColor: "#1a1e24" }
            : { ...styles.paddingBox.smtheme, backgroundColor: "#1a1e24" }
        }
      >
        {children}
      </Box>
    );
  }

  const [isVisible, setVisible] = React.useState(false);
  function handleClick() {
    setVisible((v) => !v);
  }

  function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  function DaumMap() {
    return (
      <Map
        center={{ lat: 33.5563, lng: 126.79581 }}
        style={{ width: "100%", height: "360px" }}
      >
        <MapMarker position={{ lat: 33.55635, lng: 126.795841 }}>
          <div style={{ color: "#000" }}>Hello World!</div>
        </MapMarker>
      </Map>
    );
  }
  const Marker = ({ children }) => children;

  function GoogleMap({ height = "435px" }) {
    const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
        position: { lat: 37.400909, lng: 127.112158 },
        map,
        title: "Hideep",
      });
      return marker;
    };

    return (
      <Box sx={{ maxHeight: height, width: "100%", aspectRatio: "1.9 / 1" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU",
          }}
          defaultCenter={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          styles={""}
          defaultZoom={14}
          distanceToMouse={() => {}}
          // options={defaultMapOptions}
          center={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          <Marker lat={37.400909} lng={127.112158}>
            <div
              className="cluster-marker"
              style={{
                // width: `${10 + (pointCount / points.length) * 20}px`,
                width: `${40}px`,
                // height: `${10 + (pointCount / points.length) * 20}px`
                height: `${40}px`,
                color: "black",
              }}
            >
              Hideep
            </div>
          </Marker>
        </GoogleMapReact>
      </Box>
    );
  }

  const CustomizedAccordion = styled(Accordion)(() => ({
    "& .MuiPaper-root": {
      color: "darkslategray",
      backgroundColor: "#E4FAF4",
      borderRadius: "0.6rem",
      boxShadow: "none",
    },
    paddingBottom: "1.6rem",
    boxShadow: "none",
  }));

  function match(lg, sm) {
    return matches ? lg : sm;
  }
  function snl() {
    return match("", "\n");
  }
  function onlySm(view) {
    return matches ? "" : <> {view}</>;
  }
  function onlyLg(view) {
    return matches ? <> {view} </> : "";
  }

  const AntTabs = styled(Tabs)({
    borderBottom: "1px solid #e8e8e8",
    "& .MuiTabs-indicator": {
      backgroundColor: "#1890ff",
    },
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      minWidth: 0,
      [theme.breakpoints.up("sm")]: {
        minWidth: 0,
      },
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&.Mui-selected": {
        color: "#1890ff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#d1eaff",
      },
    })
  );

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    marginLeft: "-16px",
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 0,
      width: "100%",
      backgroundColor: "#979797",
      height: "1.5px",
    },
    marginLeft: matches ? "-6px" : "-17px",
  });

  const StyledTab = styled((props) => (
    <Tab
      disableRipple
      onClick={() => history.push(props.label.split(" ").join(""))}
      {...props}
    />
  ))(({ theme }) =>
    matches
      ? {
          textTransform: "none",
          fontWeight: theme.typography.fontWeightRegular,
          fontFamily: "Inter",

          textTransform: "none",
          fontWeight: "700",
          fontSize: "18px",

          marginRight: theme.spacing(-1),
          color: "#fff",
          "&.Mui-selected": {
            color: "#979797",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "rgba(100, 95, 228, 0.32)",
          },
        }
      : {
          textTransform: "none",
          fontWeight: theme.typography.fontWeightRegular,
          fontFamily: "Inter",

          textTransform: "none",
          fontWeight: "700",
          fontSize: "14px",

          marginRight: theme.spacing(-3),
          color: "#fff",
          "&.Mui-selected": {
            color: "#979797",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "rgba(100, 95, 228, 0.32)",
          },
        }
  );
  const [value, setValue] = React.useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function MainLikeImg({ height, img }) {
    return (
      <div
        style={{
          position: "relative",
          height: height,
          textAlign: "center",
          overflow: "hidden",
          boxSizing: "border-box",
          display: "block",
          minWidth: "100%",
          lineHeight: "0",
          height: height,
          backgroundColor: "#000",
        }}
      >
        <img
          style={{
            minWidth: "100%",
            margin: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-ms-transform": " translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            lineHeight: "0",
            backgroundColor: "#000",
          }}
          src={img}
          alt=""
        ></img>
      </div>
    );
  }

  function CustomTabs() {
    return (
      <Box sx={{ bgcolor: "#000", marginLeft: "-8px" }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Touch">
            <Link></Link>
          </StyledTab>
          <StyledTab label="3D Touch">
            <Link></Link>
          </StyledTab>
          <StyledTab label="Stylus">
            <Link></Link>
          </StyledTab>
        </StyledTabs>
        <Box sx={{ p: 3 }} />
      </Box>
    );
  }
  function LgPage() {
    return (
      <React.Fragment>
        {/* 빈공간 */}
        {/* 스티키 헤더 */}
        {/* <BasicAppBar white={white} /> */}

        <BlackEmptySpace height={matches ? "192px" : "15px"} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "60px" : "60px"} />
            <CustomTabs />
            <BlackEmptySpace height={matches ? "22px" : "0px"} />

            <Typography variant={"componetsh1"}>{t(`Stylus`)}</Typography>
            <BlackEmptySpace height={matches ? "100px" : "83px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `Display 의  form factor 가 foldable, rollable, stretchable 등 화면을 접거나, 말거나, 당길 수 있도록 기술이 발전되고 있습니다.\n이로 인해 화면이 더 커지게 되고, 더 커진 화면을 더 유용하게 사용할 수 있도록 Touch 뿐 아니라 Stylus 기능이 필수적으로 요구되고 있습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "130px" : "60px"} />

            <Grid container>
              <Grid item xs={12} md={2}></Grid>

              <Grid item xs={12} md={8}>
                <ImgWidthFit src={touch1} width="100%" />

                <BlackEmptySpace height={matches ? "40px" : "83px"} />
                <Typography variant={"componentsbody1"} align="right">
                  {t(
                    `출처: 2021년 SID(Society for Information Display) 학회 SDC(삼성디스플레이) keynote speech`
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}></Grid>
            </Grid>

            <BlackEmptySpace height={matches ? "220px" : "83px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `그럼 세상에는 어떤 stylus 기술이 있을까요? 크게 2가지 종류로 나눠질 수 있고\n이는 배터리가 필요한 active stylus와 배터리가 필요하지 않은 passive stylus입니다.`
              )}
            </Typography>
            <BlackEmptySpace
              height={matches && lang == "ko" ? "30px" : "0px"}
            />
            <Typography variant={"componentsbody1"}>
              {t(
                `Active stylus 에는 이미 다양한 pen 종류와 다양한 통신 규격 (protocol) 이 혼재합니다.\nActive stylus 는 배터리가 필요하기 때문에 계속 충전을 해야 하고, 모든 기기가\n모두 다른 통신 규격으로 동작을 하기 때문에 각 기기별로 다른 Pen 이 필요한 불편함이 있습니다.`
              )}
            </Typography>
            <BlackEmptySpace
              height={matches && lang == "ko" ? "30px" : "0px"}
            />
            <Typography variant={"componentsbody1"}>
              {t(
                `Passive stylus 로 대표적인 제품은 삼성의 갤럭시 노트 모델에서 사용되고 있는 S-pen 이 있고\n앞쪽에 커다란 고무가 달려서 터치가 가능한 Conductive stylus 가 있습니다.\nPassive stylus 는 배터리가 없어서 충전할 필요는 없지만\n별도의 stylus 용 sensor 필름을 사용해야 하는 기술 구현의 불편함이 있거나\nConductive stylus 는 손가락 터치와 Stylus 를 완벽히 구분할 수 없는 기술의 한계가 있습니다. `
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "60px" : "83px"} />
            <ImgWidthFit src={touch2} />
            <BlackEmptySpace height={matches ? "60px" : "83px"} />

            <BlackEmptySpace height={matches ? "30px" : "83px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `우리는 active stylus와 passive stylus의 장점만을 갖는\nStylus 기술을 개발할 수는 없을까 라는 고민을 시작했습니다.`
              )}
            </Typography>

            <BlackEmptySpace height={matches ? "110px" : "83px"} />
            <Typography
              variant={"componentsh2"}
              sx={
                matches
                  ? {
                      fontSize: "60px",
                      fontWeight: "700",
                      textAlign: "center",
                      lineHeight: "1.35",
                      letterSpacing: "-1px",
                    }
                  : {
                      fontSize: "26px",
                      fontWeight: "700",
                      textAlign: "center",
                      lineHeight: "1.35",
                      letterSpacing: "-1px",
                    }
              }
            >
              {t(`No Protocol
No Battery
No Stylus Sensor
Single IC`)}
            </Typography>

            <BlackEmptySpace height={matches ? "210px" : "83px"} />
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            {/* 2 */}
            {/* 3 */}

            <Typography
              variant={"componentsh2"}
              sx={{ fontWeight: "700", lineHeight: "1.5", fontSize: "20px" }}
            >
              {t(
                `HIDEEP Stylus (H-Stylus™) 기술은, 월등한 성능으로\n2014년 양산을 성공했던 Conductive Stylus 부터 시작되었습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "30px" : "30px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `Conductive Stylus (Passive), 1mm tip & palm-rejection 기능 포함.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "110px" : "34px"} />

            {!white && <VideoComponentLg />}

            <BlackEmptySpace height={matches ? "210px" : "83px"} />
          </PaddingBox>
        </CustomContainer>
        <MainLikeImg height="1000px" img={newStylus} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "110px" : "83px"} />
            <CustomTabs />
          </PaddingBox>
        </CustomContainer>

        <BlackEmptySpace height={matches ? "200px" : "36px"} />
      </React.Fragment>
    );
  }

  function SmPage() {
    return (
      <React.Fragment>
        {/* 빈공간 */}
        {/* 스티키 헤더 */}
        {/* <BasicAppBar white={white} /> */}

        <BlackEmptySpace height={matches ? "192px" : "15px"} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "60px" : "60px"} />
            <CustomTabs />
            <BlackEmptySpace height={matches ? "22px" : "5px"} />

            <Typography variant={"componetsh1"}>{t(`Stylus`)}</Typography>
            <BlackEmptySpace height={matches ? "100px" : "48px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `Display 의  form factor 가 foldable, rollable, stretchable 등 화면을 접거나, 말거나, 당길 수 있도록 기술이 발전되고 있습니다.\n\n이로 인해 화면이 더 커지게 되고, 더 커진 화면을 더 유용하게 사용할 수 있도록 Touch 뿐 아니라 Stylus 기능이 필수적으로 요구되고 있습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "130px" : "40px"} />

            <Grid container>
              <Grid item xs={1} md={2}></Grid>

              <Grid item xs={10} md={8}>
                <ImgWidthFit src={touch1} width="100%" />
              </Grid>
              <Grid item xs={1} md={2}></Grid>
            </Grid>
            <BlackEmptySpace height={matches ? "0px" : "20px"} />

            <Typography variant={"componentsref"} align="left">
              {t(
                `출처: 2021년 SID(Society for Information Display) 학회 SDC(삼성디스플레이) keynote speech`
              )}
            </Typography>

            <BlackEmptySpace height={matches ? "220px" : "60px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `그럼 세상에는 어떤 stylus 기술이 있을까요? 크게 2가지 종류로 나눠질 수 있고 이는 배터리가 필요한 active stylus와 배터리가 필요하지 않은 passive stylus입니다.`
              )}
            </Typography>
            <Typography variant={"componentsbody1"}>
              {t(
                `\nActive stylus 에는 이미 다양한 pen 종류와 다양한 통신 규격 (protocol) 이 혼재합니다. Active stylus 는 배터리가 필요하기 때문에 계속 충전을 해야 하고, 모든 기기가 모두 다른 통신 규격으로 동작을 하기 때문에 각 기기별로 다른 Pen 이 필요한 불편함이 있습니다.`
              )}
            </Typography>
            <Typography variant={"componentsbody1"}>
              {t(
                `\nPassive stylus 로 대표적인 제품은 삼성의 갤럭시 노트 모델에서 사용되고 있는 S-pen 이 있고 앞쪽에 커다란 고무가 달려서 터치가 가능한 Conductive stylus 가 있습니다.\n\nPassive stylus 는 배터리가 없어서 충전할 필요는 없지만\n별도의 stylus 용 sensor 필름을 사용해야 하는 기술 구현의 불편함이 있거나\nConductive stylus 는 손가락 터치와 Stylus 를 완벽히 구분할 수 없는 기술의 한계가 있습니다. `
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "60px" : "10px"} />
            <ImgWidthFit src={touch2} />
            <BlackEmptySpace height={matches ? "60px" : "60px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `우리는 active stylus와 passive stylus의 장점만을 갖는\nStylus 기술을 개발할 수는 없을까 라는 고민을 시작했습니다.`
              )}
            </Typography>

            <BlackEmptySpace height={matches ? "110px" : "60px"} />
            <Typography
              variant={"componentsh2"}
              sx={
                matches
                  ? {
                      fontSize: "60px",
                      fontWeight: "700",
                      textAlign: "center",
                      lineHeight: "1.35",
                      letterSpacing: "-1px",
                    }
                  : {
                      fontSize: "26px",
                      fontWeight: "700",
                      textAlign: "center",
                      lineHeight: "1.35",
                      letterSpacing: "-1px",
                    }
              }
            >
              {t(`No Protocol
No Battery
No Stylus Sensor
Single IC`)}
            </Typography>

            <BlackEmptySpace height={matches ? "210px" : "75px"} />

            <Typography
              variant={"componentsh2"}
              sx={{ fontWeight: "700", lineHeight: "1.7" }}
            >
              {t(
                `HIDEEP Stylus (H-Stylus™) 기술은,\n월등한 성능으로 2014년 양산을 성공했던\nConductive Stylus 부터 시작되었습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "24px" : "40px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `Conductive Stylus (Passive), 1mm tip & palm-rejection 기능 포함.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "24px" : "41px"} />

            {/* Youtube */}
          </PaddingBox>
        </CustomContainer>

        {!white && <VideoComponentSm />}
        <CustomContainer>
          <PaddingBox>
            {/* 2 */}
            {/* 3 */}

            <BlackEmptySpace height={matches ? "200px" : "70px"} />
          </PaddingBox>
        </CustomContainer>
        <ImgWidthFit src={newStylus} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "90px" : "45px"} />
            <CustomTabs />
          </PaddingBox>
        </CustomContainer>

        <BlackEmptySpace height={matches ? "200px" : "100px"} />
      </React.Fragment>
    );
  }
  return matches ? (
    <>
      <Box
        // className={white ? `black backtransition` : `white backtransition`}
        style={{
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          flexGrow: 1,
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "90px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          zIndex: "9999999999",
          backgroundColor: white ? "#000" : "#fff",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
        }}
      >
        <Link
          sx={{ padding: "0", marginTop: "8px" }}
          underline="none"
          href="/main"
        >
          <img
            src={white ? logo_white : logo_black}
            style={{
              height: "auto",
              maxWidth: "139.27px",
            }}
          ></img>
        </Link>
        <Box
          sx={{
            flexGrow: 1,
            position: "fixed",
            top: "65px",
            left: "0px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: white ? "#000" : "#fff",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
          }}
        >
          <Link
            href="/technology"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "500",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Technology
          </Link>

          <Link
            href="/company"
            variant="h4"
            sx={{
              fontFamily: "Inter",
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Company
          </Link>
          <Link
            href="/careers"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Careers
          </Link>
          <Link
            href="/InvestorRelations"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Investor Relations
          </Link>
          <Link
            href="/newsroom"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Newsroom
          </Link>
        </Box>
      </Box>{" "}
      <LgPage white={white} />{" "}
    </>
  ) : (
    <>
      <Box
        sx={{
          flexGrow: 1,
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "48px",
          zIndex: "999",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
        }}
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: white ? "black" : "#fff",
            boxShadow: "none",
            height: "48px",
            minHeight: "48px",
            maxHeight: "48px",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
          }}
        >
          <Toolbar
            style={{
              backgroundColor: white ? "black" : "#fff",
              transition: "background-color 1000ms linear",
              boxShadow: "none",
              justifyContent: "space-between",
              display: "flex",
              alignContent: "center",
              height: "48px",
              minHeight: "48px",
              transition: "all .5s ease",
              WebkitTransition: "all .5s ease",
              MozTransition: "all .5s ease",
              transition: "all .5s ease",
              WebkitTransition: "all .5s ease",
              MozTransition: "all .5s ease",
            }}
          >
            <IconButton
              size="samll"
              edge="start"
              color={white ? "#000" : "#fff"}
              aria-label="menu"
              sx={{ mr: 2, color: "black", maxHeight: "48px" }}
              onClick={openDrawer}
            >
              <MenuIcon color={white ? "white" : "black"} />
            </IconButton>
            <Link
              underline="none"
              href="/main"
              sx={{ padding: "0", marginTop: "8px" }}
            >
              <img
                src={white ? logo_sm_white : logo_sm}
                style={{
                  marginRight: "45px",
                  height: "auto",
                  maxWidth: "56.2px",
                }}
              ></img>
            </Link>

            <Typography sx={{ height: "48px" }} color="inherit">
              {" "}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <SmPage />
    </>
  );
}

const FixedText = styled(Typography)(({ theme }) => ({
  fontFamily: "NanumBarunGothic",

  fontSize: "2.5vw",

  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  lineHeight: "4.2vw",
  fontWeight: "700",
  letterSpacing: "-0.02vw",
}));

export default Company;
