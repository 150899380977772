import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BlackEmptySpace, BasicAppBar } from "component/components";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useHistory } from "react-router";
import logo_sm from "asset/logo_sm.png";
import logo_sm_white from "asset/logo_sm_white.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import GlobalContext from "context/globalContext";
import logo_black from "asset/2023-HIDEEP-logo-black.png";
import logo_white from "asset/2023-HIDEEP-logo-gray.png";
//careers

import touch1 from "asset/technology/3dtouch/touch1.png";
import touch2 from "asset/technology/3dtouch/touch2.png";
import touch3 from "asset/technology/3dtouch/touch3.png";
import touch4 from "asset/technology/3dtouch/touch4.jpeg";
import { Link } from "@mui/material";

import tech1 from "asset/technology/tech1.jpeg";
import tech2 from "asset/technology/tech2.jpeg";
import tech3 from "asset/technology/tech3.png";
import tech4 from "asset/technology/tech4.jpeg";
import tech5 from "asset/technology/tech5.png";
import tech6 from "asset/technology/tech6.png";
import tech7 from "asset/technology/tech7.jpeg";
import tech8 from "asset/technology/tech8.jpeg";
import tech9 from "asset/technology/tech9.jpeg";
import tech10 from "asset/technology/tech10.jpeg";
import tech11 from "asset/technology/tech11.jpeg";
import tech12 from "asset/technology/tech12.jpeg";
import tech13 from "asset/technology/tech13.jpeg";
import pdf from "asset/company/pdf.png";
import GoogleMapReact from "google-map-react";
import { GoogleMap, Marker as Marker1 } from "react-google-maps";
import { borders } from "@mui/system";

import styles from "styles/style";
import i18n from "i18n";
import "./main.css";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";

import "./iamport.cm";
import { maxWidth } from "@mui/system";
import { BorderTop } from "@mui/icons-material";
function Company(props) {
  const history = useHistory();
  const youtubeURL =
    "https://www.youtube-nocookie.com/embed/QDiMxX9rr0k?autoplay=0&modestbranding=1&rel=0&fs=0";
  const { t } = useTranslation();

  const [lang, setLang] = React.useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "ko"
  );

  React.useEffect(() => {
    console.log("렌더를 어떻게 유발 시키지", lang);
    if (lang === "ko") {
      i18n.changeLanguage("ko");
    } else {
      i18n.changeLanguage("en");
    }
  }, [lang]);

  const VideoComponentLg = React.memo(() => {
    return (
      <iframe
        width="100%"
        height="717"
        src={youtubeURL}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    );
  }, []);
  const VideoComponentSm = React.memo(() => {
    return (
      <div style={{ width: "100%", aspectRatio: "1.1129", lineHeight: "0" }}>
        <iframe
          width="100%"
          height="100%"
          src={youtubeURL}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    );
  }, []);

  const matches = useMediaQuery("(min-width:900px)");
  const [hover, setHover] = React.useState(false);
  const fixedBoxRef = React.useRef();
  const textBoxRef = React.useRef();
  const lastTextRef = React.useRef();

  const boxRef = React.useRef();

  const [refState, setRefState] = React.useState(null);
  const [textRefState, setTextRefState] = React.useState(null);
  const [lastTextRefState, setLastTextRefState] = React.useState(null);

  const [boxRefState, setBoxRefState] = React.useState(null);
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const containerBottom =
    refState && refState.current.offsetTop + refState.current.offsetHeight;
  const containerTop = refState && refState.current.offsetTop;

  const containerHeight = refState && refState.current.offsetHeight;
  const fixedTop = refState && refState.current.offsetTop;
  const lastTextHeight =
    lastTextRefState && lastTextRefState.current.offsetHeight;
  const lastTextBottom =
    lastTextRefState &&
    lastTextRefState.current.offsetTop +
      lastTextRefState.current.offsetHeight +
      containerTop +
      lastTextHeight;

  const boxSize = boxRefState && boxRef.current.offsetHeight;
  const bottom = containerBottom - boxSize;
  const fixedBottom = refState && bottom;
  const fixedRange = bottom > scrollPosition && scrollPosition > fixedTop;

  const textHeight = textRefState && textRefState.current.offsetHeight;

  // React.useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  // React.useEffect(() => {
  //   if (fixedBoxRef.current) {
  //     setRefState(fixedBoxRef);
  //   }
  //   if (textBoxRef.current) {
  //     setTextRefState(textBoxRef);
  //   }
  //   if (lastTextRef.current) {
  //     setLastTextRefState(lastTextRef);
  //   }
  //   if (boxRef.current) {
  //     setBoxRefState(boxRef);
  //   }
  // }, [fixedBoxRef]);

  function ImgKeepHeight({ src }) {
    return (
      <div
        style={
          matches
            ? styles.imgKeepLengthBox.lgtheme
            : styles.imgKeepLengthBox.smtheme
        }
      >
        <img
          style={
            matches
              ? styles.imgKeepLength.lgtheme
              : styles.imgKeepLength.smtheme
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgWidthFit({ src, height, minHeight }) {
    return (
      <div
        style={
          matches
            ? {
                ...styles.imgWidthFitBox.lgtheme,
                height: height ?? "",
                backgroundColor: "black",
                lineHeight: "0",
              }
            : {
                ...styles.imgWidthFitBox.smtheme,
                height: height ?? "",
                minHeight,
                lineHeight: "0",
              }
        }
      >
        <img
          style={
            matches
              ? { ...styles.imgWidthFit.lgtheme, height: height ?? "" }
              : {
                  ...styles.imgWidthFit.smtheme,
                  height: height ?? "",
                  minHeight,
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgScaleFit({ src, height }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgScaleFitBox.lgtheme, height, overflow: "hidden" }
            : {
                ...styles.imgScaleFitBox.smtheme,
                maxHeight: height,
                overflow: "hidden",
              }
        }
      >
        <img
          style={
            matches
              ? {
                  ...styles.imgScaleFit.lgtheme,
                  minHeight: height,
                  overflow: "hidden",
                }
              : {
                  ...styles.imgScaleFit.smtheme,
                  maxHeight: height,
                  overflow: "hidden",
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function CustomContainer(props) {
    return (
      <div style={{ backgroundColor: "black" }}>
        <Container
          sx={{
            maxWidth: "1294px",
            padding: matches ? "0 5px" : "0 0px",
          }}
        >
          {props.children}
        </Container>
      </div>
    );
  }
  function PaddingBox({ children }) {
    return (
      <Box sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}>
        {children}
      </Box>
    );
  }
  function CustomColorContainer(props) {
    return (
      <div style={{ backgroundColor: "#1A1E24" }}>
        <Container
          sx={{
            maxWidth: "1294px",
            padding: matches ? "0 5px" : "0 0px",
            backgroundColor: "#1A1E24",
          }}
        >
          {props.children}
        </Container>
      </div>
    );
  }
  function PaddingColorBox({ children }) {
    return (
      <Box
        sx={
          matches
            ? { ...styles.paddingBox.lgtheme, backgroundColor: "#1a1e24" }
            : { ...styles.paddingBox.smtheme, backgroundColor: "#1a1e24" }
        }
      >
        {children}
      </Box>
    );
  }

  function MainLikeImg({ height, img }) {
    return (
      <div
        style={{
          position: "relative",
          height: height,
          textAlign: "center",
          overflow: "hidden",
          boxSizing: "border-box",
          display: "block",
          minWidth: "100%",
          lineHeight: "0",
          height: height,
          backgroundColor: "#000",
        }}
      >
        <img
          style={{
            minWidth: "100%",
            margin: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-ms-transform": " translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            lineHeight: "0",
            backgroundColor: "#000",
          }}
          src={img}
          alt=""
        ></img>
      </div>
    );
  }

  const [isVisible, setVisible] = React.useState(false);
  function handleClick() {
    setVisible((v) => !v);
  }

  function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  function DaumMap() {
    return (
      <Map
        center={{ lat: 33.5563, lng: 126.79581 }}
        style={{ width: "100%", height: "360px" }}
      >
        <MapMarker position={{ lat: 33.55635, lng: 126.795841 }}>
          <div style={{ color: "#000" }}>Hello World!</div>
        </MapMarker>
      </Map>
    );
  }
  const Marker = ({ children }) => children;

  function GoogleMap({ height = "435px" }) {
    const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
        position: { lat: 37.400909, lng: 127.112158 },
        map,
        title: "Hideep",
      });
      return marker;
    };

    return (
      <Box sx={{ maxHeight: height, width: "100%", aspectRatio: "1.9 / 1" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU",
          }}
          defaultCenter={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          styles={""}
          defaultZoom={14}
          distanceToMouse={() => {}}
          // options={defaultMapOptions}
          center={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          <Marker lat={37.400909} lng={127.112158}>
            <div
              className="cluster-marker"
              style={{
                // width: `${10 + (pointCount / points.length) * 20}px`,
                width: `${40}px`,
                // height: `${10 + (pointCount / points.length) * 20}px`
                height: `${40}px`,
                color: "black",
              }}
            >
              Hideep
            </div>
          </Marker>
        </GoogleMapReact>
      </Box>
    );
  }

  const CustomizedAccordion = styled(Accordion)(() => ({
    "& .MuiPaper-root": {
      color: "darkslategray",
      backgroundColor: "#E4FAF4",
      borderRadius: "0.6rem",
      boxShadow: "none",
    },
    paddingBottom: "1.6rem",
    boxShadow: "none",
  }));

  function match(lg, sm) {
    return matches ? lg : sm;
  }
  function snl() {
    return match("", "\n");
  }
  function onlySm(view) {
    return matches ? "" : <> {view}</>;
  }
  function onlyLg(view) {
    return matches ? <> {view} </> : "";
  }

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    marginLeft: "-16px",
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 0,
      width: "100%",
      backgroundColor: "#979797",
      height: "1.5px",
    },
    marginLeft: matches ? "-14px" : "-24px",
  });
  const [white, setWhite] = React.useState(true);

  const { drawerIsOpen, setDrawerIsOpen } = React.useContext(GlobalContext);
  const openDrawer = () => {
    setDrawerIsOpen(!drawerIsOpen);
  };
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position === 0) {
      setWhite(true);
    } else {
      setWhite(false);
    }
  };

  const StyledTab = styled((props) => (
    <Tab
      disableRipple
      onClick={() => history.push(props.label.split(" ").join(""))}
      {...props}
    />
  ))(({ theme }) =>
    matches
      ? {
          textTransform: "none",
          fontWeight: theme.typography.fontWeightRegular,
          fontFamily: "Inter",

          textTransform: "none",
          fontWeight: "700",
          fontSize: "18px",

          marginRight: theme.spacing(-1),
          color: "#fff",
          "&.Mui-selected": {
            color: "#979797",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "rgba(100, 95, 228, 0.32)",
          },
        }
      : {
          textTransform: "none",
          fontWeight: theme.typography.fontWeightRegular,
          fontFamily: "Inter",

          textTransform: "none",
          fontWeight: "700",
          fontSize: "14px",

          marginRight: theme.spacing(-3),
          color: "#fff",
          "&.Mui-selected": {
            color: "#979797",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "rgba(100, 95, 228, 0.32)",
          },
        }
  );
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function CustomTabs() {
    return (
      <Box sx={{ bgcolor: "#000" }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Touch">
            <Link></Link>
          </StyledTab>
          <StyledTab label="3D Touch">
            <Link></Link>
          </StyledTab>
          <StyledTab label="Stylus">
            <Link></Link>
          </StyledTab>
        </StyledTabs>
        <Box sx={{ p: 3 }} />
      </Box>
    );
  }
  function LgPage() {
    return (
      <React.Fragment>
        {/* 빈공간 */}
        {/* 스티키 헤더 */}
        {/* <BasicAppBar white={white} /> */}

        <BlackEmptySpace height={matches ? "192px" : "83px"} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "60px" : "60px"} />
            <CustomTabs />
            <BlackEmptySpace height={matches ? "20px" : "60px"} />

            <Typography variant={"componetsh1"}>{t(`3D Touch`)}</Typography>
            <BlackEmptySpace height={matches ? "105px" : "83px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `세상에 multi touch가 처음 등장했을 때, 두 손가락으로 지도와 사진을 확대하고 축소하는 등의 더 편리하고 쓰기 편안한 UI / UX 가 탄생했습니다.\n우리는 더 나아가 multi touch 보다 기기를 더 편하게 사용할 수 있는 방법은 없을지 고민하게 되었고, 기존 X축, Y축으로 동작하는 터치에 새로운 차원 (Z축)을 추가하여\n터치의 힘을 인식할 수 있는 3D touch 기술을 이용한 single touch UI/UX 를 제안하였습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "120px" : "60px"} />

            <Grid container>
              <Grid item xs={12} md={12}>
                <ImgWidthFit src={touch1} />
              </Grid>
              <Grid item xs={6} md={6}></Grid>
            </Grid>

            <BlackEmptySpace height={matches ? "120px" : "83px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `- 왼손은 버스 손잡이를 잡고 있는데 지도를 확대하고 싶을 때.\n- 사진첩을, 연락처를 scroll 하는데 사진, 연락처가 너무 많아서 터치를 끝없이 해야 할 때.\nJust Press!!`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "60px" : "83px"} />
            <Grid container>
              <Grid item xs={12} md={1}></Grid>

              <Grid item xs={12} md={10}>
                <ImgWidthFit src={touch2} />
              </Grid>
              <Grid item xs={6} md={1}></Grid>
            </Grid>

            <BlackEmptySpace height={matches ? "60px" : "83px"} />

            <Typography
              variant={"componentsh2"}
              sx={
                matches
                  ? { fontSize: "48px", fontWeight: "700", textAlign: "center" }
                  : {
                      fontSize: "26px",
                      fontWeight: "700",
                      textAlign: "center",
                    }
              }
            >
              {t(`C = εA/d`)}
            </Typography>

            <BlackEmptySpace height={matches ? "80px" : "83px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `3D touch를 구현하기 위한 도전.\n스마트폰 안에서 틈을 찾아내다.\n압력을 센싱하기 위해 수많은 방법을 고민하고, 시도하고, 실패하였지만 결국에는 스마트폰 display 뒤의 미세한 공간을 발견하였습니다.\n또한 스마트폰 화면을 누르게 되면 cover glass부터 display까지의 전체 부품이 휠 수 있다는 것을 발견하게 됩니다!`
              )}
            </Typography>

            <BlackEmptySpace height={matches ? "30px" : "83px"} />

            <BlackEmptySpace height={matches ? "100px" : "83px"} />
            <Grid container>
              <Grid item xs={12} md={2}></Grid>

              <Grid item xs={12} md={8}>
                <ImgWidthFit src={touch3} />
              </Grid>
              <Grid item xs={6} md={2}></Grid>
            </Grid>
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "123px" : "83px"} />

            {/* 2 */}
            {/* 3 */}
            <Typography variant={"componentsbody1"}>
              {t(
                `스마트폰 화면을 누르면  display 뒤의 공간이 눌리는 미세한 거리 변화에 따른 정전용량의 변화로 3D touch 를 구현하였고,\n이러한 새로운 힘을 인식할 수 있는 기기 들에서 더 편리하게 사용할 수 있는 다양한 single touch UI UX 를 개발해냈습니다.`
              )}
            </Typography>

            <BlackEmptySpace height={matches ? "120px" : "83px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `Touch 만으로는 쉽게 오동작을 일으킬 수 있는 자동차, 가전, 측정 기기 등 다양한 분야로\n3D touch 기술을 확대 적용 가능합니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "220px" : "83px"} />
            {/* Youtube */}

            {!white && <VideoComponentLg />}

            <BlackEmptySpace height={matches ? "210px" : "83px"} />
          </PaddingBox>
        </CustomContainer>

        <MainLikeImg height="1000px" img={touch4} />
        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "110px" : "83px"} />
            <CustomTabs />
          </PaddingBox>
        </CustomContainer>

        <BlackEmptySpace height={matches ? "180px" : "36px"} />
      </React.Fragment>
    );
  }
  function SmPage() {
    return (
      <React.Fragment>
        {/* 빈공간 */}
        {/* 스티키 헤더 */}
        {/* <BasicAppBar white={white} /> */}

        <BlackEmptySpace height={matches ? "192px" : "15px"} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "60px" : "60px"} />
            <CustomTabs />
            <BlackEmptySpace height={matches ? "20px" : "5px"} />

            <Typography variant={"componetsh1"}>{t(`3D Touch`)}</Typography>
            <BlackEmptySpace height={matches ? "105px" : "48px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `세상에 multi touch가 처음 등장했을 때, 두 손가락으로 지도와 사진을 확대하고 축소하는 등의 더 편리하고 쓰기 편안한 UI / UX 가 탄생했습니다.\n\n우리는 더 나아가 multi touch 보다 기기를 더 편하게 사용할 수 있는 방법은 없을지 고민하게 되었고, 기존 X축, Y축으로 동작하는 터치에 새로운 차원 (Z축)을 추가하여\n\n터치의 힘을 인식할 수 있는 3D touch 기술을 이용한 single touch UI/UX 를 제안하였습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "120px" : "40px"} />

            <Grid container>
              <Grid item xs={1.5} md={12}></Grid>
              <Grid item xs={9} md={12}>
                <ImgWidthFit src={touch1} />
              </Grid>
              <Grid item xs={1.5} md={6}></Grid>
            </Grid>

            <BlackEmptySpace height={matches ? "110px" : "40px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `- 왼손은 버스 손잡이를 잡고 있는데 지도를 확대하고 싶을 때.\n- 사진첩을, 연락처를 scroll 하는데 사진, 연락처가 너무 많아서 터치를 끝없이 해야 할 때.\n\nJust Press!!`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "60px" : "40px"} />
            <Grid container>
              <Grid item xs={1} md={1}></Grid>

              <Grid item xs={10} md={10}>
                <ImgWidthFit src={touch2} />
              </Grid>
              <Grid item xs={1} md={1}></Grid>
            </Grid>

            <BlackEmptySpace height={matches ? "60px" : "40px"} />

            <Typography
              variant={"componentsh2"}
              sx={
                matches
                  ? { fontSize: "48px", fontWeight: "700", textAlign: "center" }
                  : {
                      fontSize: "26px",
                      fontWeight: "700",
                      textAlign: "center",
                    }
              }
            >
              {t(`C = εA/d`)}
            </Typography>

            <BlackEmptySpace height={matches ? "80px" : "60px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `3D touch를 구현하기 위한 도전.\n스마트폰 안에서 틈을 찾아내다.\n\n압력을 센싱하기 위해 수많은 방법을 고민하고, 시도하고, 실패하였지만\n결국에는 스마트폰 display 뒤의 미세한 공간을 발견하였습니다.\n\n또한 스마트폰 화면을 누르게 되면 cover glass부터 display까지의 전체 부품이 휠 수 있다는 것을 발견하게 됩니다!`
              )}
            </Typography>

            <BlackEmptySpace height={matches ? "30px" : "30px"} />

            <Grid container>
              <Grid item xs={1} md={2}></Grid>

              <Grid item xs={10} md={8}>
                <ImgWidthFit src={touch3} />
              </Grid>
              <Grid item xs={1} md={2}></Grid>
            </Grid>
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "123px" : "40px"} />

            {/* 2 */}
            {/* 3 */}
            <Typography variant={"componentsbody1"}>
              {t(
                `스마트폰 화면을 누르면  display 뒤의 공간이 눌리는 미세한 거리 변화에 따른 정전용량의 변화로 3D touch 를 구현하였고,\n\n이러한 새로운 힘을 인식할 수 있는 기기 들에서 더 편리하게 사용할 수 있는 다양한 single touch UI UX 를 개발해냈습니다.`
              )}
            </Typography>
          </PaddingBox>
        </CustomContainer>
        <BlackEmptySpace height={matches ? "120px" : "60px"} />
        {/* Youtube */}
        {!white && <VideoComponentSm></VideoComponentSm>}
        {/* https://www.youtube-nocookie.com */}
        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "200px" : "60px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `Touch 만으로는 쉽게 오동작을 일으킬 수 있는 자동차, 가전, 측정 기기 등\n다양한 분야로 3D touch 기술을 확대 적용 가능합니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "200px" : "60px"} />
          </PaddingBox>
        </CustomContainer>

        <ImgWidthFit src={touch4} />
        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "110px" : "60px"} />
            <CustomTabs />
          </PaddingBox>
        </CustomContainer>

        <BlackEmptySpace height={matches ? "180px" : "100px"} />
      </React.Fragment>
    );
  }
  return matches ? (
    <>
      <Box
        // className={white ? `black backtransition` : `white backtransition`}
        style={{
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          flexGrow: 1,
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "90px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          zIndex: "9999999999",
          backgroundColor: white ? "#000" : "#fff",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
        }}
      >
        <Link
          sx={{ padding: "0", marginTop: "8px" }}
          underline="none"
          href="/main"
        >
          <img
            src={white ? logo_white : logo_black}
            style={{
              height: "auto",
              maxWidth: "139.27px",
            }}
          ></img>
        </Link>
        <Box
          sx={{
            flexGrow: 1,
            position: "fixed",
            top: "65px",
            left: "0px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: white ? "#000" : "#fff",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
          }}
        >
          <Link
            href="/technology"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "500",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Technology
          </Link>

          <Link
            href="/company"
            variant="h4"
            sx={{
              fontFamily: "Inter",
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Company
          </Link>
          <Link
            href="/careers"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Careers
          </Link>
          <Link
            href="/InvestorRelations"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Investor Relations
          </Link>
          <Link
            href="/newsroom"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Newsroom
          </Link>
        </Box>
      </Box>{" "}
      <LgPage white={white} />{" "}
    </>
  ) : (
    <>
      <Box
        sx={{
          flexGrow: 1,
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "48px",
          zIndex: "999",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
        }}
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: white ? "black" : "#fff",
            boxShadow: "none",
            height: "48px",
            minHeight: "48px",
            maxHeight: "48px",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
          }}
        >
          <Toolbar
            style={{
              backgroundColor: white ? "black" : "#fff",
              transition: "background-color 1000ms linear",
              boxShadow: "none",
              justifyContent: "space-between",
              display: "flex",
              alignContent: "center",
              height: "48px",
              minHeight: "48px",
              transition: "all .5s ease",
              WebkitTransition: "all .5s ease",
              MozTransition: "all .5s ease",
              transition: "all .5s ease",
              WebkitTransition: "all .5s ease",
              MozTransition: "all .5s ease",
            }}
          >
            <IconButton
              size="samll"
              edge="start"
              color={white ? "#000" : "#fff"}
              aria-label="menu"
              sx={{ mr: 2, color: "black", maxHeight: "48px" }}
              onClick={openDrawer}
            >
              <MenuIcon color={white ? "white" : "black"} />
            </IconButton>
            <Link
              underline="none"
              href="/main"
              sx={{ padding: "0", marginTop: "8px" }}
            >
              <img
                src={white ? logo_sm_white : logo_sm}
                style={{
                  marginRight: "45px",
                  height: "auto",
                  maxWidth: "56.2px",
                }}
              ></img>
            </Link>

            <Typography sx={{ height: "48px" }} color="inherit">
              {" "}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <SmPage />
    </>
  );
}

const FixedText = styled(Typography)(({ theme }) => ({
  fontFamily: "NanumBarunGothic",

  fontSize: "2.5vw",

  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  lineHeight: "4.2vw",
  fontWeight: "700",
  letterSpacing: "-0.02vw",
}));

export default Company;
