import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { EmptySpace, BasicAppBar } from "component/components";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import newsLoading from "asset/newsLoading.png";
import queryString from "query-string";
//careers
import meeting1 from "asset/careers/meeting1.jpeg";
import meeting2 from "asset/careers/meeting2.jpeg";
import office9 from "asset/careers/office9.jpeg";
import office8 from "asset/careers/office8.jpeg";
import office10 from "asset/careers/office10.jpeg";
import test3 from "asset/careers/test3.jpeg";
import test4 from "asset/careers/test4.jpeg";
import test5 from "asset/careers/test5.jpeg";
import test6 from "asset/careers/test6.jpeg";
import test7 from "asset/careers/test7.jpeg";
import work8 from "asset/careers/work8.jpeg";
import work9 from "asset/careers/work9.jpeg";
import work10 from "asset/careers/work10.jpeg";
import pdf from "asset/company/pdf.png";
import GoogleMapReact from "google-map-react";
import { GoogleMap, Marker as Marker1 } from "react-google-maps";
import { borders } from "@mui/system";

import styles from "styles/style";
import i18n from "i18n";
import "./main.css";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { app, db } from "firebaseConfig";
import {
  getStorage,
  ref,
  deleteObject,
  getMetadata,
  getDownloadURL,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  query,
  where,
  getDocs,
  startAt,
  limit,
  orderBy,
  getCountFromServer,
  startAfter,
} from "firebase/firestore";
import axios from "axios";
import moment from "moment";
import fileDownload from "js-file-download";
import { Viewer } from "@toast-ui/react-editor";
import "prismjs/themes/prism.css";
import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import Prism from "prismjs";
import Link from "@mui/material/Link";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import "./iamport.cm";
import { maxWidth } from "@mui/system";
import { BorderTop } from "@mui/icons-material";
import { useParams } from "react-router-dom";

function Company(props) {
  const { t } = useTranslation();
  const sourceRef = React.useRef();
  const [lang, setLang] = React.useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "ko"
  );
  let page = queryString.parseUrl(window.location.search).query.page;

  React.useEffect(() => {
    console.log("렌더를 어떻게 유발 시키지", lang);
    if (lang === "ko") {
      i18n.changeLanguage("ko");
    } else {
      i18n.changeLanguage("en");
    }
  }, [lang]);
  const matches = useMediaQuery("(min-width:900px)");
  const [hover, setHover] = React.useState(false);
  const fixedBoxRef = React.useRef();
  const textBoxRef = React.useRef();
  const lastTextRef = React.useRef();

  const boxRef = React.useRef();

  const [refState, setRefState] = React.useState(null);
  const [textRefState, setTextRefState] = React.useState(null);
  const [lastTextRefState, setLastTextRefState] = React.useState(null);

  const [boxRefState, setBoxRefState] = React.useState(null);

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const containerBottom =
    refState && refState.current.offsetTop + refState.current.offsetHeight;
  const containerTop = refState && refState.current.offsetTop;

  const containerHeight = refState && refState.current.offsetHeight;
  const fixedTop = refState && refState.current.offsetTop;
  const lastTextHeight =
    lastTextRefState && lastTextRefState.current.offsetHeight;
  const lastTextBottom =
    lastTextRefState &&
    lastTextRefState.current.offsetTop +
      lastTextRefState.current.offsetHeight +
      containerTop +
      lastTextHeight;

  const boxSize = boxRefState && boxRef.current.offsetHeight;
  const bottom = containerBottom - boxSize;
  const fixedBottom = refState && bottom;
  const fixedRange = bottom > scrollPosition && scrollPosition > fixedTop;

  const textHeight = textRefState && textRefState.current.offsetHeight;

  const [newsroom, setNewsroom] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      if (refresh) {
        init();
        setRefresh(false);
      }
    })();
  }, [refresh]);
  React.useEffect(() => {
    (async () => {
      init();
    })();
  }, []);

  const { id } = useParams();
  const [categ, setCateg] = React.useState(null);
  const [created, setCreated] = React.useState(null);
  const [dateOfNews, setDateOfNews] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [content, setContent] = React.useState(null);
  const [source, setSource] = React.useState(null);

  async function init() {
    const docRef = doc(db, `/company/${id}`);
    const docSnap = await getDoc(docRef);

    setContent(docSnap.data().contents);

    setTitle(docSnap.data().title);
    setCateg(docSnap.data().category);
    setDateOfNews(docSnap.data().date);
    setCreated(docSnap.data().created);
    if (docSnap.data()?.source) {
      setSource(docSnap.data().source);
    }
    setFiles(docSnap.data().files);
  }
  React.useEffect(() => {
    if (sourceRef.current) {
      console.log("소스", sourceRef.current);
    }
    // sourceRef.current && sourceRef.current.style;
    // "margin: 0px 0px 90px 90px";
  });

  async function getFirebaseDoc(table) {
    try {
      const querySnapshot = await getDocs(collection(db, table));

      const posts = querySnapshot.docs.map((v) => {
        console.log(v.id);
        return { id: v.id, data: v.data() };
      });
      return { success: true, posts };
    } catch (e) {
      console.log(e);
      return { success: false, msg: e };
    }
  }
  async function download(path) {
    const storage = getStorage(app);
    try {
      console.log("시작");
      const httpsReference = ref(storage, path);
      console.log(ref);
      const metadata = await getMetadata(httpsReference);
      const fileName = metadata.name;
      console.log("ff", fileName);
      const url = await getDownloadURL(httpsReference);

      console.log(url);
      // `url` is the download URL for 'images/stars.jpg'

      // This can be downloaded directly:
      handleDownload(url, fileName);
      return;
    } catch (e) {
      alert(e.message);
      return;
    }
  }
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        console.log(res);
        fileDownload(res.data, filename);
      });
  };
  function getFileName(path) {
    const storage = getStorage(app);
    const httpsReference = ref(storage, path);
    console.log(httpsReference);
    console.log("파일", httpsReference.name);
    return httpsReference.name;
    // getMetadata(httpsReference).then((meta) => meta.name);
  }

  function ImgKeepHeight({ src }) {
    return (
      <div
        style={
          matches
            ? styles.imgKeepLengthBox.lgtheme
            : styles.imgKeepLengthBox.smtheme
        }
      >
        <img
          style={
            matches
              ? styles.imgKeepLength.lgtheme
              : styles.imgKeepLength.smtheme
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgWidthFit({ src, height, minHeight }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgWidthFitBox.lgtheme, height: height ?? "" }
            : {
                ...styles.imgWidthFitBox.smtheme,
                height: height ?? "",
                minHeight,
              }
        }
      >
        <img
          style={
            matches
              ? { ...styles.imgWidthFit.lgtheme, height: height ?? "" }
              : {
                  ...styles.imgWidthFit.smtheme,
                  height: height ?? "",
                  minHeight,
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgScaleFit({ src, height }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgScaleFitBox.lgtheme, height, overflow: "hidden" }
            : {
                ...styles.imgScaleFitBox.smtheme,
                maxHeight: height,
                overflow: "hidden",
              }
        }
      >
        <img
          style={
            matches
              ? {
                  ...styles.imgScaleFit.lgtheme,
                  minHeight: height,
                  overflow: "hidden",
                }
              : {
                  ...styles.imgScaleFit.smtheme,
                  maxHeight: height,
                  overflow: "hidden",
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function CustomContainer(props) {
    return (
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          maxWidth: "1294px",
          padding: "0",
          padding: matches ? "0 5px" : "0 0px",
        }}
      >
        {props.children}
      </Container>
    );
  }
  function PaddingBox({ children }) {
    return (
      <Box
        sx={
          matches
            ? { ...styles.paddingBox.lgtheme, padding: "0 0px" }
            : { ...styles.paddingBox.smtheme, padding: "0 15px" }
        }
      >
        {children}
      </Box>
    );
  }
  function CustomColorContainer(props) {
    return (
      <div style={{ backgroundColor: "#1A1E24" }}>
        <Container
          sx={{
            maxWidth: "1294px",
            padding: matches ? "0 5px" : "0 0px",
            backgroundColor: "#1A1E24",
          }}
        >
          {props.children}
        </Container>
      </div>
    );
  }
  function PaddingColorBox({ children }) {
    return (
      <Box
        sx={
          matches
            ? { ...styles.paddingBox.lgtheme, backgroundColor: "#1a1e24" }
            : { ...styles.paddingBox.smtheme, backgroundColor: "#1a1e24" }
        }
      >
        {children}
      </Box>
    );
  }

  const [isVisible, setVisible] = React.useState(false);
  function handleClick() {
    setVisible((v) => !v);
  }

  function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  function DaumMap() {
    return (
      <Map
        center={{ lat: 33.5563, lng: 126.79581 }}
        style={{ width: "100%", height: "360px" }}
      >
        <MapMarker position={{ lat: 33.55635, lng: 126.795841 }}>
          <div style={{ color: "#000" }}>Hello World!</div>
        </MapMarker>
      </Map>
    );
  }
  const Marker = ({ children }) => children;

  function GoogleMap({ height = "435px" }) {
    const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
        position: { lat: 37.400909, lng: 127.112158 },
        map,
        title: "Hideep",
      });
      return marker;
    };

    return (
      <Box sx={{ maxHeight: height, width: "100%", aspectRatio: "1.9 / 1" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU",
          }}
          defaultCenter={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          styles={""}
          defaultZoom={14}
          distanceToMouse={() => {}}
          // options={defaultMapOptions}
          center={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          <Marker lat={37.400909} lng={127.112158}>
            <div
              className="cluster-marker"
              style={{
                // width: `${10 + (pointCount / points.length) * 20}px`,
                width: `${40}px`,
                // height: `${10 + (pointCount / points.length) * 20}px`
                height: `${40}px`,
                color: "black",
              }}
            >
              Hideep
            </div>
          </Marker>
        </GoogleMapReact>
      </Box>
    );
  }

  const CustomizedAccordion = styled(Accordion)(() => ({
    "& .MuiPaper-root": {
      color: "darkslategray",
      backgroundColor: "#E4FAF4",
      borderRadius: "0.6rem",
      boxShadow: "none",
    },
    paddingBottom: "1.6rem",
    boxShadow: "none",
  }));

  function match(lg, sm) {
    return matches ? lg : sm;
  }
  function snl() {
    return match("", "\n");
  }
  function onlySm(view) {
    return matches ? "" : <> {view}</>;
  }
  function onlyLg(view) {
    return matches ? <> {view} </> : "";
  }

  return (
    <React.Fragment>
      {/* 빈공간 */}
      {/* 스티키 헤더 */}
      <BasicAppBar />

      <EmptySpace height={matches ? "250px" : "90px"} />

      {/* <ImgWidthFit src={hi} /> */}
      {/* <ImgScaleFit src={connect} /> */}
      <CustomContainer>
        <PaddingBox>
          {matches ? (
            <>
              <Typography variant="newsh2">{t(`HIDEEP`)}</Typography>
              <EmptySpace height={matches ? "7px" : "0px"} />

              <Typography variant="newsh1">{t(`Public Notice`)}</Typography>
              <EmptySpace height={matches ? "115px" : "45px"} />
            </>
          ) : (
            <></>
          )}

          <Box sx={{}}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={9}>
                <Stack direction="column">
                  <Typography
                    sx={
                      matches
                        ? {
                            fontSize: "30px",
                            fontWeight: "700",
                            color: "#1d1d1d",
                            lineHeight: 1.2,
                          }
                        : {
                            fontSize: "18px",
                            fontWeight: "700",
                            color: "#1d1d1d",
                            lineHeight: 1.6,
                          }
                    }
                  >
                    {title && title}
                  </Typography>
                  <EmptySpace height={matches ? "8px" : "0px"} />

                  <Stack direction="row">
                    <Typography
                      sx={
                        matches
                          ? {
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "#979797",
                            }
                          : {
                              fontSize: "13px",
                              fontWeight: "700",
                              color: "#979797",
                            }
                      }
                    >
                      {dateOfNews && dateOfNews}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "700",
                        color: "#979797",
                      }}
                    >
                      {dateOfNews && "\u00a0\u00a0   | \u00a0\u00a0"}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#979797",
                      }}
                    >
                      {categ && categ}
                    </Typography>
                  </Stack>
                </Stack>
                <EmptySpace height={matches ? "60px" : "60px"} />
              </Grid>

              <Grid item xs={12} md={8} style={{ fontWeight: "normal" }}>
                {content && (
                  <Viewer
                    // style={{ fontWeight: "400" }}
                    initialValue={content || ""}
                    plugins={[[codeSyntaxHighlight, { highlighter: Prism }]]}
                    customHTMLRenderer={{
                      htmlBlock: {
                        iframe(node) {
                          return [
                            {
                              type: "openTag",
                              tagName: "iframe",
                              outerNewLine: true,
                              attributes: node.attrs,
                            },
                            {
                              type: "html",
                              content: node.childrenHTML,
                            },
                            {
                              type: "closeTag",
                              tagName: "iframe",
                              outerNewLine: false,
                            },
                          ];
                        },
                      },
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
          <Box mt="30px">
            {source && (
              <Box
                style={{
                  margin: "30px !important",
                }}
              >
                <Typography
                  id={"hello"}
                  className={"source"}
                  style={
                    matches
                      ? {
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000",
                          marginTop: "30px !important",
                        }
                      : {
                          fontSize: "13px",
                          fontWeight: "400",
                          color: "#000",
                          marginTop: "30px !important",
                        }
                  }
                  sx={
                    matches
                      ? {
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000",
                          marginTop: "30px !important",
                        }
                      : {
                          fontSize: "13px",
                          fontWeight: "400",
                          color: "#000",
                          marginTop: "30px !important",
                        }
                  }
                >
                  {`출처 : \u00a0`}
                  <a
                    href="#"
                    onClick={() => {
                      if (source) {
                        window.open(source);
                      }
                    }}
                    sx={
                      matches
                        ? { fontSize: "14px", marginTop: "90px" }
                        : {
                            fontSize: "13px",
                            marginTop: "30px",
                          }
                    }
                  >
                    {source}
                  </a>
                </Typography>
              </Box>
            )}
            <EmptySpace height={matches ? "60px" : "30px"} />
            {title &&
              files &&
              files.map((f) => (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      color={"#1d1d1d"}
                      underline="none"
                      download
                      component="button"
                      onClick={() => {
                        download(f);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <AttachFileIcon
                          sx={
                            matches
                              ? { mr: 4 }
                              : {
                                  height: "22px",
                                  mr: 3,
                                }
                          }
                        />{" "}
                        <Typography
                          sx={
                            matches
                              ? {
                                  fontWeight: "700",
                                  fontSize: "15px",
                                  marginRight: "15px",
                                  color: "#1d1d1d",
                                }
                              : {
                                  fontWeight: "700",
                                  fontSize: "10px",
                                  marginRight: "15px",
                                  color: "#1d1d1d",
                                  minWidth: "44px",
                                }
                          }
                        >
                          다운로드
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: matches ? "15px" : "10px",
                            marginRight: "15px",
                            color: "#1d1d1d",
                          }}
                        >
                          |{" "}
                        </Typography>
                        {getFileName(f)}
                      </Box>
                    </Link>
                  </Box>
                </>
              ))}
            <EmptySpace height={matches ? "10px" : "10px"} />

            {title && (
              <Button
                size="small"
                sx={{
                  backgroundColor: "#f3f3f3",
                  color: "#000",
                  fontWeight: "bold",
                  "&.MuiButtonBase-root": {
                    borderRadius: "0",
                  },
                  "&:hover": {
                    backgroundColor: "#f3f3f3",
                  },
                }}
                href={`/InvestorRelations?page=${page}`}
              >
                {t("목록")}
              </Button>
            )}
          </Box>
        </PaddingBox>
      </CustomContainer>

      <EmptySpace height={matches ? "210px" : "100px"} />
    </React.Fragment>
  );
}

const FixedText = styled(Typography)(({ theme }) => ({
  fontFamily: "NanumBarunGothic",

  fontSize: "2.5vw",

  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  lineHeight: "4.2vw",
  fontWeight: "700",
  letterSpacing: "-0.02vw",
}));

export default Company;
